import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import NumberFormat from "react-number-format";
import { APPLICATIONS, LICENSE_TYPES } from "../../config/Constants";
import { BtnEmpty } from "../shared/BtnEmpty";
import { BtnFill } from "../shared/BtnFill";
import { BwmSelect } from "../shared/form/BwmSelect";
import DatePicker from "react-datepicker";

export function AdminNewApplicationModal(props) {

    const defaultYear = new Date().getFullYear() + 1;
    const customer = props.customer;
    const [modalShow, setModalShow] = useState(false);
    const [application, setApplication] = useState(2);
    const [licenseType, setLicenseType] = useState("BOUGHT");
    const [maxSubjects, setMaxSubjects] = useState(1);
    const [expiration, setExpiration] = useState(new Date().setFullYear(defaultYear));

    const onSave = () => {
        if(expiration && application && maxSubjects && licenseType){
            const customerApplication = {
                expiration:new Date(expiration),
                application:{id: application},
                maxSubjects:maxSubjects,
                licenseType: licenseType,
                customer: customer
            }
            props.onSave(customerApplication);

        }
    }   

    const openModal = () => {
        setModalShow(true);
    };

    const closeModal = () => {
        setModalShow(false);
    };


    return (
        <React.Fragment>
            <button type="button" className="ms-2 btn btn-primary btn-new-rel btn-sm " onClick={openModal}>
                <strong>+</strong>
            </button>
            <Modal
                show={modalShow}
                onHide={closeModal}
                size={"lg"}
                aria-labelledby="contained-modal-title-vcenter"
                dialogClassName="modal-content-sm"
                centered>
                <div className="bar"></div>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h2>Aggiungi Applicazione per l'utente {customer?.user?.login}</h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-blue">

                    <div className="row p-2 bg-blue  m-auto">
                        <div className='col-6 '>
                            <BwmSelect
                                label={"Applicazione"}
                                className="form-control"
                                value={application}
                                options={APPLICATIONS}
                                onChange={(e) => setApplication(e.target.value)}
                            /></div>
                        <div className='col-6  form-group'>
                            <label>Numero Soggetti</label>
                            <NumberFormat
                                decimalScale={0}
                                fixedDecimalScale={true}
                                className="form-control"
                                inputMode="numeric"
                                value={maxSubjects}
                                onChange={e => setMaxSubjects(e.target.value)} />
                        </div>
                        <div className='col-6 '>
                            <BwmSelect
                                label={"Tipo licenza"}
                                className="form-control"
                                value={licenseType}
                                options={LICENSE_TYPES}
                                onChange={(e) => setLicenseType(e.target.value)}
                            /></div>
                        <div className='col-6 form-group'>
                            <label>Scadenza</label>
                            <DatePicker
                                key={'dateField-new'}
                                selected={new Date(expiration)}
                                onChange={(date) => setExpiration(date)}
                                className={"form-control"}
                                locale="it"
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                showMonthDropdown
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <BtnEmpty text="ANNULLA" classCustom="float-start" handlerOnClick={closeModal} />
                    <BtnFill text="CREA E CONTINUA" classCustom="float-end text-end"
                        handlerOnClick={() => onSave()} />
                </Modal.Footer>
            </Modal>


        </React.Fragment>
    )
}