import {GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS, GET_ADMIN_CUSTOMERS_SUCCESS, GET_USER_PREFERENCES_SUCCESS} from '../actions/types';

const INITIAL_STATE = {
    customers: {
        data: [],
        errors: []
    },
    customerApplications: {
        data: [],
        errors: []
    },
    userPreferences:{
        data: [],
        errors: []
    }
};

export const customersReducer = (state = INITIAL_STATE.customers, action) => {
    switch(action.type) {
        case GET_ADMIN_CUSTOMERS_SUCCESS:
            return {...state, data: action.customers, errors: []};
        default:
            return state;
    }
};

export const customerApplicationReducer = (state = INITIAL_STATE.customerApplications, action) => {
    switch(action.type) {
        case GET_ADMIN_CUSTOMER_APPLICATION_SUCCESS:
            return {...state, data: action.customerApplications, errors: []};
        default:
            return state;
    }
};
export const userPreferencesReducer = (state = INITIAL_STATE.userPreferences, action) => {
    switch(action.type) {
        case GET_USER_PREFERENCES_SUCCESS:
            return {...state, data: action.userPreferences, errors: []};
        default:
            return state;
    }
}