
import { ReactComponent as IInfo } from '../../styles/images/svg/info-circle.svg';
import React from "react";

/**
 * 
 * @param {*} simplePointsArr array composto da oggetti di tipo {title:"",text:""}
 * @returns 
 */
export function InfoBox ({simplePointsArr=[], additionalClasses}){


    const renderSimpleInfoPoints = (arr) => {
        if(arr){
            return arr.map((el, index) => {
                return (
                    <div key={"infobox-"+index} className={`infoPoint ${el.aditionalInfoPointClass || ''}`}>
                        <strong className={`${el.aditionalInfoTitleClass || ''}`}>{el.title ?`${el.title}: `: ``}</strong>
                    <span className={`${el.aditionalInfoTextClass || ''}`}>{`  ${el.text}`}</span>
                    </div>
                )
            })
        }
    }

    return (
            <div className={`infoPostaEle ${additionalClasses ? additionalClasses : ""}`}>
                <div className={`infoPoint-title`}><IInfo fill="#b8b8b8"
                    width="20" /> Informazioni
                </div>
                {renderSimpleInfoPoints(simplePointsArr)}
            </div>
    )

}