import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';


class CustomerBox extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.props.logout();
        this.props.history.push('/');
    }

    getInitialUser() {
        if (this.props.auth.user) {
            let name = `${this.props.auth.user.lastName} ${this.props.auth.user.firstName}`;
            let initials = name.split(' ');
            let initialsUser = '';
            initials.forEach(i => {
                initialsUser+=i.substr(0,1).toUpperCase();
            });
            return initialsUser;
        }
    }

    openAssistancePage(url) {
        window.open(url)
    }

    render() {
        return (
            <div className={"customerBox  "}  onMouseLeave={() => this.props.mouseLeave()}>
                <div className="customer-info p-3">
                    <div className="customer-user">
                        {this.props.auth.user.firstName && <span className="user">{`${this.props.auth.user.lastName || ''} ${this.props.auth.user.firstName}`}</span>}
                        <span className="company">{this.props.auth.user.customer.companyName}</span>
                        <span className="mail">{this.props.auth.user.login}</span>
                    </div>
                </div>
                <div className="logout">
                    {this.props.auth.isAuth &&
                    <a className='nav-item nav-link clickable' onClick={this.handleLogout}>Logout</a>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        application: state.application,
        userPreferences:state.userPreferences
    }
}

export default withRouter(connect(mapStateToProps)(CustomerBox));
